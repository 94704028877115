import { combineEpics, Epic, ofType } from 'redux-observable';
import { map, catchError, of, mergeMap, withLatestFrom, EMPTY } from 'rxjs';
import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import {
  filterCustomersFetched,
  filterCustomersFetchFailed,
  filterCustomerSystemsFetched,
  filterCustomerSystemsFetchFailed,
  filterProductsFetched,
  filterProductsFetchFailed,
  filterTradingSystemsFetched,
  filterTradingSystemsFetchFailed,
  filterUserSystemsFetched,
  filterUserSystemsFetchFailed,
} from './filters.actions';
import {
  isCustomerFilterOptionsFetched,
  isCustomerSystemFilterOptionsFetched,
  isTradingSystemFilterOptionsFetched,
  isUserSystemFilterOptionsFetched,
} from './filters.selectors';
import { tradingSystemsFilterOptionsFetchedThunk } from './filters.thunks';
import { defaultCustomer, defaultCustomerSystemId, defaultTradingSystem } from './filtersHelper';
import { filtersApiConnector, FiltersApi } from 'api/filters';
import { DcEpic } from 'store/epics';
import {
  QUERY_CUSTOMER_SYSTEM_UPDATED,
  QUERY_CUSTOMER_UPDATED,
  QUERY_EXCHANGE_UPDATED,
  QUERY_TRADING_SYSTEM_UPDATED,
  QueryCustomerUpdated,
  QueryExchangeUpdated,
  QueryTradingSystemUpdated,
} from 'store/queries/queries.actions';
import {
  getSelectedCustomer,
  getSelectedCustomerSystemId,
  getSelectedExchange,
  getSelectedTradingSystem,
  isFeatureEnabled,
  isProductFilterOptionsFetched,
} from 'store/selectors';
import { RootState } from 'store/state';
import { httpClient } from 'utils/http/httpClient';

const filtersApi$ = filtersApiConnector(httpClient);

export const filterProductsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED),
      withLatestFrom<QueryExchangeUpdated, [RootState]>(state$),
      mergeMap(([{ value: exchange }, state]) => {
        if (!exchange) {
          return EMPTY;
        }

        if (isProductFilterOptionsFetched(exchange)(state)) {
          return EMPTY;
        }

        return api$.getFilterOptions(exchange, 'Products').pipe(
          map(response => filterProductsFetched(exchange, response)),
          catchError((err: DcErrorResponse) => of(filterProductsFetchFailed(exchange, err))),
        );
      }),
    );

export const filterCustomersRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED),
      withLatestFrom<QueryExchangeUpdated, [RootState]>(state$),
      mergeMap(([{ value: exchange }, state]) => {
        if (!exchange) {
          return EMPTY;
        }

        if (isCustomerFilterOptionsFetched(exchange)(state)) {
          return EMPTY;
        }

        return api$.getFilterOptions(exchange, 'Customers').pipe(
          map(response => filterCustomersFetched(exchange, response)),
          catchError((err: DcErrorResponse) => of(filterCustomersFetchFailed(exchange, err))),
        );
      }),
    );

export const filterTradingSystemsRequestedEpicWithToggleOff =
  (api$: FiltersApi): DcEpic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_CUSTOMER_UPDATED),
      withLatestFrom<QueryCustomerUpdated, [RootState]>(state$),
      mergeMap(([{ value: customer, queryType }, state]) => {
        if (isFeatureEnabled('UnrestrictedListQueryFilters')(state)) {
          return EMPTY;
        }
        const exchange = getSelectedExchange(queryType)(state);

        if (!exchange || !customer) {
          return EMPTY;
        }

        if (isTradingSystemFilterOptionsFetched(exchange, customer)(state)) {
          return EMPTY;
        }

        return api$.getFilterOptions(exchange, 'Trading-systems', { customer }).pipe(
          map(response => filterTradingSystemsFetched(exchange, customer, response)),
          catchError((err: DcErrorResponse) => of(filterTradingSystemsFetchFailed(exchange, customer, err))),
        );
      }),
    );

export const filterTradingSystemsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED, QUERY_CUSTOMER_UPDATED),
      withLatestFrom<QueryExchangeUpdated | QueryCustomerUpdated, [RootState]>(state$),
      mergeMap(([{ queryType }, state]) => {
        if (isFeatureEnabled('UnrestrictedListQueryFilters')(state)) {
          const exchange = getSelectedExchange(queryType)(state);
          const customer = getSelectedCustomer(queryType)(state);

          if (!exchange) {
            return EMPTY;
          }

          if (isTradingSystemFilterOptionsFetched(exchange, customer ?? defaultCustomer)(state)) {
            return EMPTY;
          }

          return api$.getFilterOptions(exchange, 'Trading-systems', { customer }).pipe(
            map(response =>
              tradingSystemsFilterOptionsFetchedThunk(exchange, customer ?? defaultCustomer, response, queryType),
            ),
            catchError((err: DcErrorResponse) =>
              of(filterTradingSystemsFetchFailed(exchange, customer ?? defaultCustomer, err)),
            ),
          );
        }
        return EMPTY;
      }),
    );

export const filterCustomerSystemsRequestedEpicWithToggleOff =
  (api$: FiltersApi): DcEpic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_TRADING_SYSTEM_UPDATED),
      withLatestFrom<QueryTradingSystemUpdated, [RootState]>(state$),
      mergeMap(([{ value: tradingSystem, queryType }, state]) => {
        if (isFeatureEnabled('UnrestrictedListQueryFilters')(state)) {
          return EMPTY;
        }
        const exchange = getSelectedExchange(queryType)(state);
        const customer = getSelectedCustomer(queryType)(state);

        if (!exchange || !customer || !tradingSystem) {
          return EMPTY;
        }

        if (isCustomerSystemFilterOptionsFetched(exchange, customer, tradingSystem)(state)) {
          return EMPTY;
        }

        return api$.getFilterOptions(exchange, 'Customer-systems', { customer, tradingSystem }).pipe(
          map(response => filterCustomerSystemsFetched(exchange, customer, tradingSystem, response)),
          catchError((err: DcErrorResponse) =>
            of(filterCustomerSystemsFetchFailed(exchange, customer, tradingSystem, err)),
          ),
        );
      }),
    );

export const filterCustomerSystemsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED, QUERY_CUSTOMER_UPDATED, QUERY_TRADING_SYSTEM_UPDATED),
      withLatestFrom<QueryExchangeUpdated | QueryCustomerUpdated | QueryTradingSystemUpdated, [RootState]>(state$),
      mergeMap(([{ queryType }, state]) => {
        if (isFeatureEnabled('UnrestrictedListQueryFilters')(state)) {
          const exchange = getSelectedExchange(queryType)(state);
          const customer = getSelectedCustomer(queryType)(state);
          const tradingSystem = getSelectedTradingSystem(queryType)(state);

          if (!exchange) {
            return EMPTY;
          }

          if (isCustomerSystemFilterOptionsFetched(exchange, customer ?? defaultCustomer, tradingSystem ?? defaultTradingSystem)(state)) {
            return EMPTY;
          }

          return api$.getFilterOptions(exchange, 'Customer-systems', { customer, tradingSystem }).pipe(
            map(response => filterCustomerSystemsFetched(exchange, customer ?? defaultCustomer, tradingSystem ?? defaultTradingSystem, response)),
            catchError((err: DcErrorResponse) =>
              of(filterCustomerSystemsFetchFailed(exchange, customer ?? defaultCustomer, tradingSystem ?? defaultTradingSystem, err)),
            ),
          );
        }
        return EMPTY;
      }),
    );

export const filterUserSystemsRequestedEpicWithToggleOff =
  (api$: FiltersApi): DcEpic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_CUSTOMER_SYSTEM_UPDATED),
      withLatestFrom<QueryCustomerUpdated, [RootState]>(state$),
      mergeMap(([{ value: customerSystemId, queryType }, state]) => {
        const exchange = getSelectedExchange(queryType)(state);
        const customer = getSelectedCustomer(queryType)(state);
        const tradingSystem = getSelectedTradingSystem(queryType)(state);

        if (isFeatureEnabled('UnrestrictedListQueryFilters')(state)) {
          return EMPTY;
        }

        if (!exchange || !customer || !tradingSystem || !customerSystemId) {
          return EMPTY;
        }

        if (isUserSystemFilterOptionsFetched(exchange, customer, tradingSystem, customerSystemId)(state)) {
          return EMPTY;
        }

        return api$
          .getFilterOptions(exchange, 'User-systems', { customer, tradingSystem, customerId: customerSystemId })
          .pipe(
            map(response => filterUserSystemsFetched(exchange, customer, tradingSystem, customerSystemId, response)),
            catchError((err: DcErrorResponse) =>
              of(filterUserSystemsFetchFailed(exchange, customer, tradingSystem, customerSystemId, err)),
            ),
          );
      }),
    );

export const filterUserSystemsRequestedEpic =
  (api$: FiltersApi): DcEpic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED, QUERY_CUSTOMER_UPDATED, QUERY_TRADING_SYSTEM_UPDATED, QUERY_CUSTOMER_SYSTEM_UPDATED),
      withLatestFrom<QueryExchangeUpdated | QueryCustomerUpdated | QueryTradingSystemUpdated | QueryCustomerUpdated, [RootState]>(state$),
      mergeMap(([{ queryType }, state]) => {
        const exchange = getSelectedExchange(queryType)(state);
        const customer = getSelectedCustomer(queryType)(state);
        const tradingSystem = getSelectedTradingSystem(queryType)(state);
        const customerSystemId = getSelectedCustomerSystemId(queryType)(state);

        if (isFeatureEnabled('UnrestrictedListQueryFilters')(state)) {
          if (!exchange) {
            return EMPTY;
          }
  
          if (isUserSystemFilterOptionsFetched(exchange, customer ?? defaultCustomer, tradingSystem ?? defaultTradingSystem, customerSystemId ?? defaultCustomerSystemId)(state)) {
            return EMPTY;
          }
  
          return api$
            .getFilterOptions(exchange, 'User-systems', { customer, tradingSystem, customerId: customerSystemId })
            .pipe(
              map(response => filterUserSystemsFetched(exchange, customer ?? defaultCustomer, tradingSystem ?? defaultTradingSystem, customerSystemId ?? defaultCustomerSystemId, response)),
              catchError((err: DcErrorResponse) =>
                of(filterUserSystemsFetchFailed(exchange, customer ?? defaultCustomer, tradingSystem ?? defaultTradingSystem, customerSystemId ?? defaultCustomerSystemId, err)),
              ),
            );
        }
        return EMPTY;
      }),
    );

export const filtersEpics = (): DcEpic =>
  combineEpics(
    filterProductsRequestedEpic(filtersApi$),
    filterCustomersRequestedEpic(filtersApi$),
    filterTradingSystemsRequestedEpicWithToggleOff(filtersApi$),
    filterTradingSystemsRequestedEpic(filtersApi$),
    filterCustomerSystemsRequestedEpicWithToggleOff(filtersApi$),
    filterCustomerSystemsRequestedEpic(filtersApi$),
    filterUserSystemsRequestedEpicWithToggleOff(filtersApi$),
    filterUserSystemsRequestedEpic(filtersApi$),
  );
