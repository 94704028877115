import { filterTradingSystemsFetched } from './filters.actions';
import { QueryType } from 'models/Queries';
import { FilterOptionsResponse } from 'models/Responses/FilterOptionsResponse';
import { Thunk } from 'store/actions';
import { queryTradingSystemErased } from 'store/queries/queries.actions';
import { shouldEraseSelectedTradingSystem } from 'store/queries/query.helpers';
import { getSelectedTradingSystem } from 'store/selectors';
import { isDefinedAndNotEmpty } from 'utils/string/string';

export const tradingSystemsFilterOptionsFetchedThunk =
  (exchange: string, customer: string, response: FilterOptionsResponse, queryType: QueryType): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.users.current?.features.UnrestrictedListQueryFilters) {
      dispatch(filterTradingSystemsFetched(exchange, customer, response));
      if (isDefinedAndNotEmpty(exchange)) {
        const selectedTradingSystem = getSelectedTradingSystem(queryType)(state);
        if (shouldEraseSelectedTradingSystem(customer, true, selectedTradingSystem, response.options)) {
          dispatch(queryTradingSystemErased(queryType));
        }
      }
    } else {
      dispatch(queryTradingSystemErased(queryType));
    }
  };
