import {
  QuerySelectedFilterActions,
  queryAccountUpdated,
  queryBusinessDateRangeUpdated,
  queryCustomerSystemUpdated,
  queryCustomerUpdated,
  queryExchangeOrderIdUpdated,
  queryExchangeUpdated,
  queryFunctionalStatusUpdated,
  queryPriceUpdated,
  queryProductUpdated,
  queryQuantityUpdated,
  querySideUpdated,
  queryTimeInForceUpdated,
  queryTradingSystemUpdated,
  queryUserSystemUpdated,
} from './queries.actions';
import { ExchangeInfo } from 'models/ExchangeInfo';
import { FilterOption } from 'models/FilterOption';
import { OrderFunctionalStatus } from 'models/Orders/OrderFunctionalStatus';
import { OrderSide } from 'models/Orders/OrderSide';
import { OrderTimeInForce } from 'models/Orders/TimeInForce';
import { QueryType } from 'models/Queries';
import { QueryFilters, BusinessDateRange, FilterType } from 'models/QueryFilters';
import { isDefinedAndNotEmpty } from 'utils/string/string';

export function getQueryFilterUpdatedAction(
  queryType: QueryType,
  filterType: FilterType,
  value: QueryFilters[FilterType],
  exchangeInfo?: ExchangeInfo,
): QuerySelectedFilterActions | undefined {
  switch (filterType) {
    case 'account':
      return queryAccountUpdated(queryType, filterType, value as string | undefined);
    case 'businessDates':
      return queryBusinessDateRangeUpdated(queryType, filterType, value as BusinessDateRange);
    case 'customer':
      return queryCustomerUpdated(queryType, filterType, value as string | undefined);
    case 'exchange':
      return queryExchangeUpdated(queryType, filterType, value as string | undefined, exchangeInfo);
    case 'exchangeOrderId':
      return queryExchangeOrderIdUpdated(queryType, filterType, value as string | undefined);
    case 'functionalStatus':
      return queryFunctionalStatusUpdated(queryType, filterType, value as OrderFunctionalStatus[] | undefined);
    case 'price':
      return queryPriceUpdated(queryType, filterType, value as number | undefined);
    case 'productDescription':
      return queryUserSystemUpdated(queryType, filterType, value as string | undefined);
    case 'product':
      return queryProductUpdated(queryType, filterType, value as string | undefined);
    case 'quantity':
      return queryQuantityUpdated(queryType, filterType, value as number | undefined);
    case 'side':
      return querySideUpdated(queryType, filterType, value as OrderSide[] | undefined);
    case 'tradingSystem':
      return queryTradingSystemUpdated(queryType, filterType, value as string | undefined);
    case 'customerSystemId':
      return queryCustomerSystemUpdated(queryType, filterType, value as string | undefined);
    case 'userSystemId':
      return queryUserSystemUpdated(queryType, filterType, value as string | undefined);
    case 'timeInForce':
      return queryTimeInForceUpdated(queryType, filterType, value as OrderTimeInForce | undefined);
    default:
      return undefined;
  }
}

export function shouldEraseSelectedTradingSystem(
  customer: string,
  isTradingSystemFilterOptionsFetched: boolean,
  tradingSystem: string | undefined,
  tradingSystemFilterOptions: FilterOption[],
): boolean {
  if (!isDefinedAndNotEmpty(customer) || !isTradingSystemFilterOptionsFetched || !isDefinedAndNotEmpty(tradingSystem)) {
    return false;
  }
  return !tradingSystemFilterOptions.map(option => option.value).includes(tradingSystem);
}
